import { api, ApiResponse } from '@/lib/api';
import { IPaginatedList } from '@/types/api';
import { IPaymentAnnouncementFilters, IPaymentAnnouncement } from './paymentAnnouncements.types';

export const paymentAnnouncementsRepo = {
  getPaymentAnnouncements: (
    params: IPaymentAnnouncementFilters
  ): ApiResponse<IPaginatedList<IPaymentAnnouncement>> => {
    return api.get('/user/bo/withdrawal-reservation', {
      params,
    });
  },
  cancelPayment: (paymentId: number) => {
    return api.put(`/payments/lb/bo/cancel-withdrawal-reservation/${paymentId}`);
  },
};
