import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import { productUserTransactionsService } from './productUserTransactions.service';
import {
  IProductUserTransaction,
  IProductUserTransactionFilters,
} from './productUserTransactions.types';
import { IPaginatedList } from '@/types/api';

@Module({ dynamic: true, store, name: 'productUserTransactions', namespaced: true })
class ProductUserTransactions extends VuexModule {
  productUserTransactions: IProductUserTransaction[] = [];
  totalTransactions = 0;

  @Mutation
  setProductUserTransactions(payload: IPaginatedList<IProductUserTransaction>) {
    this.productUserTransactions = [...this.productUserTransactions, ...payload.items];
    this.totalTransactions = payload.total;
  }

  @Mutation
  clearTransactions() {
    this.productUserTransactions = [];
  }

  @Action
  async fetchProductUserTransactions(params: IProductUserTransactionFilters) {
    const [err, res] = await to(productUserTransactionsService.getProductUserTransactions(params));
    if (err || !res) return Promise.reject(err);
    this.setProductUserTransactions(res);

    return Promise.resolve(res);
  }
}

export const ProductUserTransactionsStore = getModule(ProductUserTransactions);
