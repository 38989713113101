import { productUserTransactionsRepo } from './productUserTransactions.repo';
import { IProductUserTransactionFilters } from './productUserTransactions.types';
import filterPayload from '@/lib/removeFalsyValues';

export const productUserTransactionsService = {
  getProductUserTransactions: (params: IProductUserTransactionFilters) => {
    const filteredParams = filterPayload(params);
    return productUserTransactionsRepo.getProductUserTransactions(filteredParams);
  },
};
