import { i18n } from '@/ui/locales/setupLocale';

export const paymentStatusOptions = [
  {
    value: '__CANCELED__',
    label: i18n.t('transactionReporting.canceled'),
  },
  {
    value: '__PENDING_VALIDATION__',
    label: i18n.t('transactionReporting.pending'),
  },
  {
    value: '__EXPIRED__',
    label: i18n.t('transactionReporting.expired'),
  },
  {
    value: '__CLOSED__',
    label: i18n.t('transactionReporting.closed'),
  },
  {
    value: '__PROCESSING__',
    label: i18n.t('transactionReporting.processing'),
  },
  {
    value: '__ACTIVE__',
    label: i18n.t('transactionReporting.active'),
  },
];

export const paymentStatusMap: { [key: string]: string } = {
  __PENDING_VALIDATION__: 'Pending validation',
  __EXPIRED__: 'Expired',
  __CLOSED__: 'Closed',
  __CANCELED__: 'Canceled',
  __PROCESSING__: 'Processing',
  __ACTIVE__: 'Active',
};

export const withdrawalTypeMap: { [key: string]: string } = {
  __CASH__: 'Cash',
  __BANK__: 'Bank deposit',
};

export const withdrawalTypeOptions = [
  { value: '__CASH__', label: i18n.t('transactionReporting.cash') },
  { value: '__BANK__', label: i18n.t('transactionReporting.bankDeposit') },
];
