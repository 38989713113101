import dayjs from 'dayjs';
import writeXlsxFile from 'write-excel-file';

interface IColDefs {
  headerName?: any;
  field?: string;
  excelWidth?: number;
  cellRenderer?: any;
}

export const writeExcel = async (
  dataArray: object[],
  colDefs: IColDefs[],
  fileNamePrefix: string
) => {
  const columns = colDefs.map(col => ({
    //todo, skip columns with action buttons
    column: col.headerName,
    type: String,
    width: col.excelWidth || 80,
    value: (record: any) => {
      const cellText = record[col.field || ''];
      return `${cellText}` || '';
    },
  }));

  await writeXlsxFile(dataArray, {
    schema: columns,
    fileName: `${fileNamePrefix}_${dayjs().format('DD.MM.YYYY')}.xlsx`,
  });
};
