import { paymentAnnouncementsRepo } from './paymentAnnouncements.repo';
import { IPaymentAnnouncementFilters } from './paymentAnnouncements.types';
import filterPayload from '@/lib/removeFalsyValues';

export const paymentAnnouncementsService = {
  getPaymentAnnouncements: (params: IPaymentAnnouncementFilters) => {
    const filteredParams = filterPayload(params);
    return paymentAnnouncementsRepo.getPaymentAnnouncements(filteredParams);
  },
  cancelPayment: (paymentId: number) => {
    return paymentAnnouncementsRepo.cancelPayment(paymentId);
  },
};
