
import { Component, Vue, Prop } from 'vue-property-decorator';

import { IPaymentAnnouncement } from '@/modules/paymentAnnouncements/paymentAnnouncements.types';
import {
  paymentStatusMap,
  withdrawalTypeMap,
} from '@/modules/paymentAnnouncements/paymentAnnouncements.constants';
import dayjs from 'dayjs';
import { LONG_DATE_TIME } from '@/constants';

@Component
export default class PaymentAnnouncementModal extends Vue {
  @Prop()
  data!: IPaymentAnnouncement;

  @Prop()
  isVisible!: boolean;

  @Prop()
  onClose!: () => void;

  withdrawalTypeMap = withdrawalTypeMap;

  paymentStatusMap = paymentStatusMap;

  formatDestination(destination: string) {
    if (destination === '__CASH__') return this.data.betting_place_name;
    return 'Bank';
  }

  formatDate(date: string) {
    return dayjs(date).format(LONG_DATE_TIME);
  }
}
