
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import PaymentAnnouncements from './components/PaymentAnnouncements/PaymentAnnouncements.vue';
import ProductUserTransactions from './components/ProductUserTransactions/ProductUserTransactions.vue';

@Component({
  name: 'TransactionReportingPage',
  components: {
    ProductUserTransactions,
    PaymentAnnouncements,
  },
})
export default class TransactionReportingPage extends Vue {}
