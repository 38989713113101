
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import dayjs from 'dayjs';

import { LONG_DATE_TIME } from '@/constants';
import { BettingPlaceStore } from '@/modules/bettingPlace/bettingPlace.store';
import {
  paymentStatusOptions,
  withdrawalTypeOptions,
} from '@/modules/paymentAnnouncements/paymentAnnouncements.constants';

@Component
export default class PaymentAnnouncementsFilters extends Vue {
  form!: WrappedFormUtils;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  longDateTime = LONG_DATE_TIME;

  paymentStatusOptions = paymentStatusOptions;

  withdrawalTypeOptions = withdrawalTypeOptions;

  get bettingPlaceOptions() {
    return BettingPlaceStore.bettingPlaces.map(bettingPlace => ({
      label: `${bettingPlace.address} | ${bettingPlace.city}`,
      value: bettingPlace.betting_place_id,
    }));
  }

  async handleSubmit() {
    const formData = this.form.getFieldsValue();
    if (formData.date_from) formData.date_from = dayjs(formData.date_from).toISOString();
    if (formData.date_to) formData.date_to = dayjs(formData.date_to).toISOString();

    this.$emit('filtersSubmitted', formData);
  }

  mounted() {
    BettingPlaceStore.getAllBettingPlaces();
  }

  handleExport() {
    this.$emit('handleExport');
  }

  filterOption(input: string, option: any) {
    return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0;
  }
}
