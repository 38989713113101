
import { Component, Vue, Prop } from 'vue-property-decorator';

import {
  paymentStatusMap,
  withdrawalTypeMap,
} from '@/modules/paymentAnnouncements/paymentAnnouncements.constants';
import dayjs from 'dayjs';
import { LONG_DATE_TIME } from '@/constants';
import { IProductUserTransaction } from '@/modules/productUserTransactions/productUserTransactions.types';

@Component
export default class ProductUserTransactionModal extends Vue {
  @Prop()
  data!: IProductUserTransaction;

  @Prop()
  isVisible!: boolean;

  @Prop()
  onClose!: () => void;

  withdrawalTypeMap = withdrawalTypeMap;

  paymentStatusMap = paymentStatusMap;

  formatDate(date: string) {
    return dayjs(date).format(LONG_DATE_TIME);
  }
}
