
import { Component, Mixins } from 'vue-property-decorator';

import { writeExcel } from '@/lib/writeExcel';
import { WithDataTable } from '@/ui/mixins/WithDataTable';
import { ProductUserTransactionsStore } from '@/modules/productUserTransactions/productUserTransactions.store';
import ProductUserTransactionFilters from './components/ProductUserTransactionsFilters.vue';
import { IProductUserTransaction } from '@/modules/productUserTransactions/productUserTransactions.types';
import ProductUserTransactionModal from './components/ProductUserTransactionModal.vue';
import { DateFormatter } from '@/ui/components/CellRenderers/DateFormatter';

@Component({
  name: 'ProductUserTransactions',
  components: { ProductUserTransactionFilters, ProductUserTransactionModal },
})
export default class ProductUserTransactions extends Mixins(WithDataTable) {
  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('transactionReporting.transactionId'),
          field: 'tid',
          width: 280,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.username'),
          field: 'username',
          width: 150,
          excelWidth: 50,
        },
        {
          headerName: this.$t('transactionReporting.time'),
          field: 'created_at',
          width: 120,
          excelWidth: 50,
          valueFormatter: DateFormatter,
        },
        {
          headerName: this.$t('transactionReporting.amount'),
          field: 'amount',
          width: 120,
          excelWidth: 30,
        },
        {
          headerName: this.$t('transactionReporting.balanceBefore'),
          field: 'balance_before',
          width: 120,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.balanceAfter'),
          field: 'balance_after',
          width: 120,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.type'),
          field: 'transaction_type',
          width: 100,
          excelWidth: 30,
        },
        {
          headerName: this.$t('transactionReporting.system'),
          field: 'transaction_origin',
          width: 100,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.slipShortId'),
          field: 'slip_short_id',
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.casinoGame'),
          field: 'casino_game_id',
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.casinoSystem'),
          field: 'casino_system',
          width: 120,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.casinoProvider'),
          field: 'casino_provider',
          width: 120,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.virtualGame'),
          field: 'game_name',
          width: 120,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.numberGame'),
          field: 'game_round_id',
          width: 120,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.numberGameDrawId'),
          field: 'game_ticket_id',
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.operatorId'),
          field: 'operator_id',
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.location'),
          field: 'betting_place_id',
          excelWidth: 40,
        },
      ],
      rowModelType: 'infinite',
    };
  }

  isDetailsModalVisible = false;
  selectedTransaction: IProductUserTransaction = {} as IProductUserTransaction;

  mounted() {
    ProductUserTransactionsStore.clearTransactions();
  }

  filterValues = {};

  get tableData() {
    return ProductUserTransactionsStore.productUserTransactions;
  }

  async fetchData() {
    this.gridApi?.showLoadingOverlay();
    await ProductUserTransactionsStore.fetchProductUserTransactions({
      ...this.filterValues,
      limit: this.limit,
      offset: this.offset,
    });
  }

  get totalRecords() {
    return ProductUserTransactionsStore.totalTransactions;
  }

  get gridOptions() {
    return { rowStyle: { cursor: 'pointer' } };
  }

  handleFiltersSubmitted(data: any) {
    this.filterValues = data;
    ProductUserTransactionsStore.clearTransactions();
    this.gridApi?.setDatasource(this.dataSource);
  }

  handleExport() {
    writeExcel(this.tableData, this.tableProps.columnDefs, 'transakcije_nad_proizvodima');
  }

  handleCloseModal() {
    this.isDetailsModalVisible = false;
  }

  onRowClicked(event: any) {
    this.selectedTransaction = event.data;
    this.isDetailsModalVisible = true;
  }
}
