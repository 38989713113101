
import { WrappedFormUtils } from 'ant-design-vue/types/form/form';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';

import { LONG_DATE_TIME } from '@/constants';
import { generateSelectOptionsFromArr } from '@/lib/generateSelectOptionsFromArr';
import dayjs from 'dayjs';

@Component
export default class ProductUserTransactionFilters extends Vue {
  form!: WrappedFormUtils;

  beforeCreate() {
    this.form = this.$form.createForm(this);
  }

  longDateTime = LONG_DATE_TIME;

  numberGameOptions = generateSelectOptionsFromArr(['Greeko', 'Lyno6', 'Kinto']);

  originOptions = generateSelectOptionsFromArr([
    'landbase',
    'web_bet',
    'web_payment',
    'web_casino',
    'web_games',
    'games_landbase',
  ]);

  async handleSubmit() {
    const formData = this.form.getFieldsValue();
    if (formData.date_from) formData.date_from = dayjs(formData.date_from).toISOString();
    if (formData.date_to) formData.date_to = dayjs(formData.date_to).toISOString();

    this.$emit('filtersSubmitted', formData);
  }

  handleExport() {
    this.$emit('handleExport');
  }
}
