import { api, ApiResponse } from '@/lib/api';
import { IPaginatedList } from '@/types/api';
import {
  IProductUserTransaction,
  IProductUserTransactionFilters,
} from './productUserTransactions.types';

export const productUserTransactionsRepo = {
  getProductUserTransactions: (
    params: IProductUserTransactionFilters
  ): ApiResponse<IPaginatedList<IProductUserTransaction>> => {
    return api.get('/user/bo/transactions', {
      params,
    });
  },
};
