
import to from 'await-to-js';
import { Component, Vue } from 'vue-property-decorator';

import { PaymentAnnouncementsStore } from '@/modules/paymentAnnouncements/paymentAnnouncements.store';
import { IPaymentAnnouncement } from '@/modules/paymentAnnouncements/paymentAnnouncements.types';

@Component
export default class CancelPaymentButton extends Vue {
  params!: { updateCanceledPayment: (paymentId: number) => void; data: IPaymentAnnouncement };

  get isBtnVisible() {
    if (!this.params.data) return false;
    return this.params.data.status === '__ACTIVE__';
  }

  successMessage() {
    this.$notification.success({
      message: this.$t('common.success') as string,
      description: this.$t('transactionReporting.cancelPaymentSuccess') as string,
    });
  }

  async handleClick() {
    const { id } = this.params.data;
    const [err] = await to(PaymentAnnouncementsStore.cancelPayment(id));
    if (err) return;
    this.params.updateCanceledPayment(id);
    this.successMessage();
  }
}
