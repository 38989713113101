
import { Component, Mixins } from 'vue-property-decorator';

import { PaymentAnnouncementsStore } from '@/modules/paymentAnnouncements/paymentAnnouncements.store';
import { writeExcel } from '@/lib/writeExcel';
import { WithDataTable } from '@/ui/mixins/WithDataTable';
import PaymentAnnouncementsFilters from './components/PaymentAnnouncementsFilters.vue';
import CancelPaymentButton from './components/CancelPaymentButton.vue';
import { ICellRendererParams } from '@ag-grid-community/core';
import {
  paymentStatusMap,
  withdrawalTypeMap,
} from '@/modules/paymentAnnouncements/paymentAnnouncements.constants';
import { IPaymentAnnouncement } from '@/modules/paymentAnnouncements/paymentAnnouncements.types';
import PaymentAnnouncementModal from './components/PaymentAnnouncementModal.vue';
import { DateFormatter } from '@/ui/components/CellRenderers/DateFormatter';

@Component({
  name: 'PaymentAnnouncements',
  components: { PaymentAnnouncementsFilters, CancelPaymentButton, PaymentAnnouncementModal },
})
export default class PaymentAnnouncements extends Mixins(WithDataTable) {
  get tableProps() {
    return {
      columnDefs: [
        {
          headerName: this.$t('transactionReporting.paymentCode'),
          field: 'code',
          excelWidth: 40,
          width: 130,
        },
        {
          headerName: this.$t('transactionReporting.username'),
          field: 'platform_username',
          width: 130,
        },
        {
          headerName: this.$t('transactionReporting.withdrawalType'),
          field: 'withdrawal_type',
          width: 120,
          valueFormatter: ({ value }: ICellRendererParams) => withdrawalTypeMap[value],
        },
        {
          headerName: this.$t('transactionReporting.withdrawalAmount'),
          field: 'amount',
          width: 130,
        },
        {
          headerName: this.$t('transactionReporting.balance'),
          field: 'balance',
          width: 120,
          excelWidth: 50,
        },
        {
          headerName: this.$t('transactionReporting.scheduleTime'),
          field: 'created_at',
          width: 150,
          excelWidth: 30,
          valueFormatter: DateFormatter,
        },
        {
          headerName: this.$t('transactionReporting.destination'),
          field: 'betting_place_id',
          valueFormatter: this.destinationFormatter,
          width: 200,
          excelWidth: 40,
        },
        {
          headerName: this.$t('transactionReporting.status'),
          field: 'status',
          valueFormatter: ({ value }: ICellRendererParams) => paymentStatusMap[value],
          width: 160,
          excelWidth: 30,
        },
        {
          width: 100,
          cellRendererFramework: 'CancelPaymentButton',
          cellRendererParams: {
            updateCanceledPayment: this.updateCanceledPayment,
          },
          flex: 1,
        },
      ],
      rowModelType: 'infinite',
    };
  }

  selectedPaymentAnnouncement: IPaymentAnnouncement = {} as IPaymentAnnouncement;
  isDetailsModalVisible = false;

  mounted() {
    PaymentAnnouncementsStore.clearAnnouncements();
  }

  updateCanceledPayment(paymentId: number) {
    this.gridApi?.forEachNode(node => {
      if (node.data.id === paymentId) {
        node.setData({ ...node.data, status: 'Canceled' });
      }
    });
  }

  destinationFormatter({ data }: ICellRendererParams) {
    if (!data) return;
    if (data.withdrawal_type === '__CASH__') return data.betting_place_name;
    return 'Bank';
  }

  filterValues = {};

  get tableData() {
    return PaymentAnnouncementsStore.paymentAnnouncements;
  }

  async fetchData() {
    this.gridApi?.showLoadingOverlay();
    await PaymentAnnouncementsStore.fetchPaymentAnnouncements({
      ...this.filterValues,
      limit: this.limit,
      offset: this.offset,
    });
  }

  get totalRecords() {
    return PaymentAnnouncementsStore.totalAnnouncements;
  }

  get gridOptions() {
    return { rowStyle: { cursor: 'pointer' } };
  }

  handleFiltersSubmitted(data: any) {
    PaymentAnnouncementsStore.clearAnnouncements();
    this.filterValues = data;
    this.gridApi?.setDatasource(this.dataSource);
  }

  handleExport() {
    writeExcel(this.tableData, this.tableProps.columnDefs, 'najave_isplata');
  }

  handleCloseModal() {
    this.isDetailsModalVisible = false;
  }

  onRowClicked(event: any) {
    if (event.event.target.innerText === this.$t('transactionReporting.cancel')) return;
    this.selectedPaymentAnnouncement = event.data;
    this.isDetailsModalVisible = true;
  }
}
