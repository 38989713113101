import { Action, getModule, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import to from 'await-to-js';

import store from '@/store';
import { paymentAnnouncementsService } from './paymentAnnouncements.service';
import { IPaginatedList } from '@/types/api';
import { IPaymentAnnouncement, IPaymentAnnouncementFilters } from './paymentAnnouncements.types';

@Module({ dynamic: true, store, name: 'paymentAnnouncements', namespaced: true })
class PaymentAnnouncements extends VuexModule {
  paymentAnnouncements: IPaymentAnnouncement[] = [];
  totalAnnouncements = 0;

  @Mutation
  setPaymentAnnouncements(payload: IPaginatedList<IPaymentAnnouncement>) {
    this.paymentAnnouncements = [...this.paymentAnnouncements, ...payload.items];
    this.totalAnnouncements = payload.total;
  }

  @Mutation
  clearAnnouncements() {
    this.paymentAnnouncements = [];
  }

  @Action
  async fetchPaymentAnnouncements(params: IPaymentAnnouncementFilters) {
    const [err, res] = await to(paymentAnnouncementsService.getPaymentAnnouncements(params));
    if (err || !res) return Promise.reject(err);
    this.setPaymentAnnouncements(res);

    return Promise.resolve(res);
  }

  @Action
  async cancelPayment(paymentId: number) {
    const [err, res] = await to(paymentAnnouncementsService.cancelPayment(paymentId));
    if (err || !res) return Promise.reject(err);

    return Promise.resolve(res);
  }
}

export const PaymentAnnouncementsStore = getModule(PaymentAnnouncements);
